import React from "react"
import { Link } from "react-router-dom"
import { DicePages } from "./data_dice_pages.js"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUserGraduate } from "@fortawesome/free-solid-svg-icons"
import { faUserCog } from "@fortawesome/free-solid-svg-icons"
import { faUserCheck } from "@fortawesome/free-solid-svg-icons"
import { faUserEdit } from "@fortawesome/free-solid-svg-icons"
import { faChevronCircleLeft } from "@fortawesome/free-solid-svg-icons"
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"
import "./Home.css"

function HomeMobile() {
  const icons = [faUserCog, faUserGraduate, faUserEdit, faUserCheck]

  return (
    <>
      <div className="text_div Home_mobile_title">
        "I manage projects around Digitalization.
        <br />
        <span id="dice_span" style={{ fontSize: "28px" }}>
          click through the arrows
        </span>{" "}
        and find out more about me!"
      </div>
      <div className="carousel_container">
        <Carousel
          showStatus={false}
          showThumbs={false}
          infiniteLoop={true}
          showIndicators={false}
          renderArrowPrev={(onClickHandler, hasPrev, label) =>
            hasPrev && (
              <button type="button" onClick={onClickHandler} title={label} style={{ backgroundColor: "var(--blue-color-one)", color: "rgb(66, 66, 66)", position: "absolute", zIndex: 2, top: "60px", width: 50, height: 50, border: "none", borderRadius: "25px", cursor: "pointer", left: 5 }}>
                <FontAwesomeIcon className="fa-1x" icon={faChevronCircleLeft} style={{ borderRadius: "25px", backgroundColor: "var(--blue-color-one)", width: "94%", height: "94%", transform: "translateY(1px)" }} />
              </button>
            )
          }
          renderArrowNext={(onClickHandler, hasNext, label) =>
            hasNext && (
              <button type="button" onClick={onClickHandler} title={label} style={{ backgroundColor: "var(--blue-color-one)", color: "rgb(66, 66, 66)", position: "absolute", zIndex: 2, top: "60px", width: 50, height: 50, border: "none", borderRadius: "25px", cursor: "pointer", right: 5 }}>
                <FontAwesomeIcon className="fa" icon={faChevronCircleRight} style={{ borderRadius: "25px", backgroundColor: "var(--blue-color-one)", width: "94%", height: "94%", transform: "translateY(1px)" }} />
              </button>
            )
          }
        >
          {DicePages.map((item, index) => {
            return (
              <div key={index}>
                <div className="face_div_content" style={{ height: "205px", backgroundColor: "var(--main-color)", boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px", borderRadius: "25px", padding: "10px" }}>
                  <FontAwesomeIcon className="fa-4x dice-icon" icon={icons[index]} />
                  <p className="face_div_header">{item.divHeader}</p>
                </div>
                <div style={{ borderRadius: "25px", padding: "20px", marginTop: "30px", marginBottom: "20px", backgroundColor: "var(--main-color)", boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px" }}>
                  <p className="face_div_paragraph paragraphs_mobile">{item.divParagraph}</p>
                  <Link className="face_div_link" to={item.link}>
                    <div className="face_div_button" id="skills" style={{ marginTop: "15px" }}>
                      click for details
                    </div>
                  </Link>
                </div>
              </div>
            )
          })}
        </Carousel>
      </div>
    </>
  )
}

export default HomeMobile
