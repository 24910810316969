import React from "react"
import "./lazy_loading.css"

function LazyLoading() {
  return (
    <>
      <div className="main_div">
        <div className="loading_div">
          <h3>loading ...</h3>
        </div>
      </div>
    </>
  )
}

export default LazyLoading
