import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { useLocation } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronCircleLeft } from "@fortawesome/free-solid-svg-icons"
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons"
import { faUserGraduate } from "@fortawesome/free-solid-svg-icons"
import { faUserCog } from "@fortawesome/free-solid-svg-icons"
import { faUserCheck } from "@fortawesome/free-solid-svg-icons"
import { faUserEdit } from "@fortawesome/free-solid-svg-icons"
import { DicePages } from "./data_dice_pages.js"
import "./Home.css"
import background from "../assets/Background_img_home_1.png"

import gsap from "gsap"

function Home() {
  const icons = [faUserCog, faUserGraduate, faUserEdit, faUserCheck]
  const location = useLocation()
  let [currentPosition, setCurrentPosition] = useState(location.state !== null ? location.state.value : -15)
  let elements = document.getElementsByClassName("face")
  //const cube = {}

  useEffect(() => {
    let rots = [
      { ry: 0, rx: 0 }, // 1
      { ry: 90, rx: 0 }, // 2
      { ry: 180, rx: 0 }, // 3
      { ry: 270, rx: 0 }, // 4
      { ry: 0, rx: 90 }, // 5
      { ry: 0, rx: -90 } // 6
    ]

    gsap.set(elements, {
      // apply transform rotations to each face of the cube
      rotateY: i => rots[i].ry,
      rotateX: i => rots[i].rx,
      transformOrigin: "50% 50% -150px",
      z: 150
    })
    // eslint-disable-next-line no-undef
    gsap.set(cube, { rotationX: 0, rotationY: `${currentPosition}` }) // set initial cube position
  }, [])

  useEffect(() => {
    // eslint-disable-next-line no-undef
    gsap.to(cube, { rotateX: 0, rotateY: `${currentPosition}`, ease: "expo.inOut" })
  }, [currentPosition])

  function HandleOnClickSpin(inp) {
    setCurrentPosition(currentPosition + inp)
  }

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div className="image_div">
        <img className="background_img" src={background} alt="background" />
      </div>
      <div className="text_container">
        <div className="text_div">
          "I manage projects around Digitalization.
          <br />
          <span id="dice_span">Roll the dice</span> and find out more about me!"
        </div>
      </div>
      <div className="main_div">
        <div className="outer_container">
          <FontAwesomeIcon onClick={() => HandleOnClickSpin(-90)} className="fa-5x arrow" icon={faChevronCircleLeft} style={{ marginLeft: "5%", transform: "translateX(-80%)", backgroundColor: "var(--blue-color-one)", borderRadius: "50px" }} />
          <div className="container">
            <div id="cube">
              <div className="face">
                <div className="face_divs">
                  <FontAwesomeIcon className="fa-4x dice-icon" icon={icons[0]} />
                  <div className="face_div_content">
                    <p className="face_div_header">{DicePages[0].divHeader}</p>
                    <p className="face_div_paragraph">{DicePages[0].divParagraph}</p>
                    <Link className="face_div_link" to={DicePages[0].link}>
                      <div className="face_div_button" id="experience">
                        click for details
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="face">
                <div className="face_divs">
                  <FontAwesomeIcon className="fa-4x dice-icon" icon={icons[1]} />
                  <div className="face_div_content">
                    <p className="face_div_header">{DicePages[1].divHeader}</p>
                    <p className="face_div_paragraph">{DicePages[1].divParagraph}</p>
                    <Link className="face_div_link" to={DicePages[1].link}>
                      <div className="face_div_button" id="education">
                        click for details
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="face">
                <div className="face_divs">
                  <FontAwesomeIcon className="fa-4x dice-icon" icon={icons[2]} />
                  <div className="face_div_content">
                    <p className="face_div_header">{DicePages[2].divHeader}</p>
                    <p className="face_div_paragraph">{DicePages[2].divParagraph}</p>
                    <Link className="face_div_link" to={DicePages[2].link}>
                      <div className="face_div_button" id="skills">
                        click for details
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="face">
                <div className="face_divs">
                  <FontAwesomeIcon className="fa-4x dice-icon" icon={icons[3]} />
                  <div className="face_div_content">
                    <p className="face_div_header">{DicePages[3].divHeader}</p>
                    <p className="face_div_paragraph">{DicePages[3].divParagraph}</p>
                    <Link className="face_div_link" to={DicePages[3].link}>
                      <div className="face_div_button" id="references">
                        click for details
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="face">
                <div className="face_divs"></div>
              </div>
              <div className="face">
                <div className="face_divs"></div>
              </div>
            </div>
          </div>
          <FontAwesomeIcon onClick={() => HandleOnClickSpin(90)} className="fa-5x arrow" icon={faChevronCircleRight} style={{ marginRight: "5%", transform: "translateX(80%)", backgroundColor: "var(--blue-color-one)", borderRadius: "50px" }} />
        </div>
      </div>
    </div>
  )
}

export default Home
