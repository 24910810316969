import React, { useContext } from "react"
import "./Navbar.css"
import { Link } from "react-router-dom"
import MainContext from "../../MainContext"
import Pic from "../../assets/Website_pic.jpg"

function Navbar() {
  const { togglePosition, widthOverlay, MoveToggleOnClick, normalScreenSize } = useContext(MainContext)
  const profilePictureToggle = (
    <div className="toggle-div">
      <div className="overlaying-div" style={{ width: `${widthOverlay}px` }}></div>
      <div className="toggle-text">
        <h3>Andreas Gratz</h3>
        <p>PM, PO, Agile, CAPM, MSc</p>
      </div>
      <img
        className="toggle-image"
        src={Pic}
        alt="profile image"
        onClick={() => {
          MoveToggleOnClick()
        }}
        style={{ transform: `translate(${togglePosition}px)` }}
      />
      <div className="toggle-circle"></div>
    </div>
  )
  const profilePictureNOToggle = (
    <div className="toggle-div">
      <img id="no-toggle-image" className="toggle-image" src={Pic} alt="profile image" />
    </div>
  )

  return (
    <nav>
      {normalScreenSize ? profilePictureToggle : profilePictureNOToggle}
      <div className="link-div">
        <Link className="link" to="/">
          <div id="link-home" className="link-text">
            Home
          </div>
        </Link>
        <Link className="link" to="/contact">
          <div id="link-contact" className="link-text">
            Contact Me
          </div>
        </Link>
      </div>
    </nav>
  )
}

export default Navbar
