export const DicePages = [
  {
    link: "/experience",
    divHeader: "Project-Experience",
    divParagraph: "My expertise lies in Agile Software Development. I have had numerous roles within this context, like PM or PO"
  },
  {
    link: "/education",
    divHeader: "Education",
    divParagraph: "I hold a Master's degree in Management and Economics and am a certified expert in agile and classic project work"
  },
  {
    link: "/skills",
    divHeader: "Hard & Soft Skills",
    divParagraph: "I succeed through my strong technical expertise in Software Development and exceptional soft skills around teamwork"
  },
  {
    link: "/references",
    divHeader: "My References",
    divParagraph: "Throughout my career, I have given my everything to make projects succeed. Here is what others say about my work"
  }
]
