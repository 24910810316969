import React, { useEffect, useState, Suspense } from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import LazyLoading from "./components/lazy_loading"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons"
import MainContext from "./MainContext"
import "./Main.css"
import Pic from "./assets/Website_pic.jpg"

// my components
import Navbar from "./components/Navbar/Navbar.js"
import Footer from "./components/Footer/Footer.js"
import Home from "./components/Home.js"
import HomeMobile from "./components/Home_mobile.js"
const Contact = React.lazy(() => import("./components/Contact.js"))
const Experience = React.lazy(() => import("./components/experience.js"))
const Education = React.lazy(() => import("./components/education.js"))
const Skills = React.lazy(() => import("./components/skills.js"))
const References = React.lazy(() => import("./components/references.js"))
// import { AutomaticPrefetchPlugin } from "webpack"

export function Main() {
  const [position_loader, setPositon_loader] = useState(0)
  const [closeOverlayPressed, setCloseOverlayPressed] = useState(false)
  // START: variables and functions to be used in Navbar
  const [togglePosition, setTogglePosition] = useState(-12)
  const [widthOverlay, setWidthOverlay] = useState(290)
  const [toggleState, setToggleState] = useState(false)
  let counter = 0
  let adjustedValue = [-12, -220]
  let adjustedWidth = [290, 74]
  let now
  // conditional rendering of home depending on screen-size
  const [normalScreenSize, setNormalScreenSize] = useState(() => window.matchMedia("(min-width: 650px)").matches)

  function MoveToggleOnClick() {
    now = performance.now()

    if (toggleState === false && adjustedValue[0] >= -220 && now - counter > 3) {
      adjustedValue[0] -= 3 // amend to change the speed
      adjustedWidth[0] -= 3 // amend to change the speed
      setTogglePosition(adjustedValue[0])
      setWidthOverlay(adjustedWidth[0])
      setToggleState(true)
      counter += 3

      requestAnimationFrame(MoveToggleOnClick)
    }
    if (toggleState === true && adjustedValue[1] <= -12 && now - counter > 3) {
      adjustedValue[1] += 3 // amend to change the speed
      adjustedWidth[1] += 3 // amend to change the speed
      setTogglePosition(adjustedValue[1])
      setWidthOverlay(adjustedWidth[1])
      setToggleState(false)
      counter += 3

      requestAnimationFrame(MoveToggleOnClick)
    }
  }
  // END: variables and functions to be used in Navbar

  useEffect(() => {
    let counter = 0
    function myDelayAnimation(now) {
      if (now >= 3000) {
        // this has to have the same value as below, multiplied by 100
        setCloseOverlayPressed(currentState => {
          // useState is asynchronous, I here need to get the latest state
          if (currentState === false) {
            MoveToggleOnClick()
            return !currentState
          }
        })
      } else {
        if (now - counter > 10) {
          setPositon_loader(now / 30) // this has to have the same value as above, divided by 100
          counter += 10
        }
        requestAnimationFrame(myDelayAnimation)
      }
    }
    myDelayAnimation()

    let mediaQuery = window.matchMedia("(min-width: 650px)")
    mediaQuery.addEventListener("change", e => {
      setNormalScreenSize(e.matches)
    })
    return () =>
      mediaQuery.removeEventListener("change", e => {
        setNormalScreenSize(e.matches)
      }) // cleanup function
  }, [])

  return (
    <MainContext.Provider value={{ togglePosition, widthOverlay, MoveToggleOnClick, normalScreenSize }}>
      {closeOverlayPressed === false && (
        <div className="overlay-background">
          <div className="overlay-message">
            <FontAwesomeIcon
              className="fa-3x icon-div"
              icon={faTimesCircle}
              onClick={() => {
                MoveToggleOnClick()
                setCloseOverlayPressed(prev => !prev)
              }}
              style={{ background: `linear-gradient(var(--blue-color-one) 0 0) padding-box, conic-gradient(var(--blue-color-one) 0% ${position_loader}%, var(--main-color) ${position_loader}% 100%) border-box` }}
            />
            <div className="text-icon-container">
              <img src={Pic} alt="file could not load" style={{ width: "150px", borderRadius: "50%", paddingBottom: "12px" }} />
              <h1 className="overlay-text-h1">Hello and welcome to my website!</h1>
            </div>
          </div>
        </div>
      )}
      <BrowserRouter>
        <Navbar />
        <Suspense fallback={<LazyLoading />}>
          <Routes>
            <Route path="/" exact element={normalScreenSize ? <Home /> : <HomeMobile />} />
            <Route path="/contact" exact element={<Contact />} />
            <Route path="/experience" element={<Experience />} />
            <Route path="/education" element={<Education />} />
            <Route path="/skills" element={<Skills />} />
            <Route path="/references" element={<References />} />
          </Routes>
        </Suspense>
        <Footer />
      </BrowserRouter>
    </MainContext.Provider>
  )
}
