import React from "react"
import { useLocation } from "react-router-dom"
import "./Footer.css"
import ReactIcon from "../../assets/React.png"
import CompanyLogo from "../../assets/Company_Logo.png"

function Footer() {
  const location = useLocation()

  return (
    <>
      {location.pathname === "/contact" && (
        <div id="footer-div_contact" className="footer-div">
          <div className="React-image-text">
            <img className="React-image" src={ReactIcon} alt="" />
            <div>
              <span id="Written-with-React-text">Written with REACT</span>
              <br />
              <a className="Footer-Links" href="../../assets/Licenses.pdf" target="_blank">
                Used libraries and licenses
              </a>
            </div>
          </div>
          <div className="other_links_contact_no_logo">
            <div id="dummy-div-1" className="Footer-dummy-div"></div>
            <a className="Footer-Links" href="../../assets/Privacy Policy.pdf" target="_blank">
              Privacy Policy
            </a>
            <div id="dummy-div-2" className="Footer-dummy-div"></div>
            <a className="Footer-Links" href="../../assets/Disclaimer.pdf" target="_blank">
              Disclaimer
            </a>
          </div>
          <div id="CompanyLogo_image_div_contact" className="CompanyLogo_image_div">
            <img className="CompanyLogo_image" src={CompanyLogo} alt="" />
          </div>
        </div>
      )}
      {location.pathname !== "/contact" && (
        <div id="footer-div_no_contact" className="footer-div">
          <div className="React-image-text mobile_no_contact">
            <img className="React-image" src={ReactIcon} alt="" />
            <div>
              <span id="Written-with-React-text">Written with REACT</span>
              <br />
              <a className="Footer-Links" href="../../assets/Licenses.pdf" target="_blank">
                Used libraries and licenses
              </a>
            </div>
          </div>
          <div id="privacy_policy_no_contact" className="other_links_no_contact">
            <a className="Footer-Links links_no_contact" href="../../assets/Privacy Policy.pdf" target="_blank">
              Privacy Policy
            </a>
            <a className="Footer-Links links_no_contact" href="../../assets/Disclaimer.pdf" target="_blank">
              Disclaimer
            </a>
            <div className="CompanyLogo_image_div">
              <img className="CompanyLogo_image" src={CompanyLogo} alt="" />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Footer
